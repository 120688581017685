import { Carousel, IconButton } from "@material-tailwind/react";

const Announcements = ({ slideData, handleMaximize, color }) => {
  return (
    <div className="m-auto container mt-8">
      <h1 className={`font-bold text-2xl text-left pl-3 ${color}`}>
        Announcements 📨
      </h1>
      <Carousel
        className="rounded-xl items-center mt-5"
        autoplay
        loop
        prevArrow={({ handlePrev }) => (
          <IconButton
            variant="text"
            color="black"
            size="lg"
            onClick={(event) => {
              event.stopPropagation();
              handlePrev();
            }}
            className="bg-[#051d49] !absolute top-2/4 left-4 -translate-y-2/4 rounded-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </IconButton>
        )}
        nextArrow={({ handleNext }) => (
          <IconButton
            variant="text"
            color="black"
            size="lg"
            onClick={(event) => {
              event.stopPropagation();
              handleNext();
            }}
            className="!absolute top-2/4 !right-4 -translate-y-2/4 bg-[#051d49] rounded-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </IconButton>
        )}
        navigation={({ setActiveIndex, activeIndex, length }) => (
          <>
            <IconButton
              variant="text"
              color="black"
              size="lg"
              onClick={() => {
                handleMaximize(activeIndex);
              }}
              className="!absolute bottom-0 !right-4 -translate-y-2/4 bg-[#051d49] rounded-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="white"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                />
              </svg>
            </IconButton>
            <div
              className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2 bg-[#051d49]"
              style={{ padding: "5px", borderRadius: "inherit" }}
            >
              {new Array(length).fill("").map((_, i) => (
                <span
                  key={i}
                  className={`block h-2 cursor-pointer rounded-2xl transition-all content-[''] ${activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                    }`}
                  onClick={(event) => {
                    event.stopPropagation();
                    setActiveIndex(i);
                  }}
                />
              ))}
            </div>
          </>
        )}
      >
        {slideData
          .slice()
          .reverse()
          .map((slide, key) => (
            <div key={key}>
              <iframe
                src={slide.Link.Url}
                scrolling="no"
                allowFullScreen
                frameBorder="0"
                style={{ height: "70vh", width: "100%" }}
                className="h-auto w-auto object-contain m-auto"
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
              ></iframe>
              {"CSHLink" in slide ? (
                <div className="absolute inset-0 grid h-full w-full place-items-center">
                  <a
                    href={slide.CSHLink.Url}
                    target="_blank"
                    style={{ width: "100%", height: "100%" }}
                  ></a>
                </div>
              ) : null}
            </div>
          ))}
      </Carousel>
    </div>
  );
};

export default Announcements;
