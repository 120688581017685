import { useState } from "react";
import copilotSVG from "../assets/copilot.svg";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./Fab.css";

function Fab() {
  const [copilotShow, setCopilotShow] = useState(false);

  const toggleState = (g, s) => {
    s(!g);
  };

  return (
    <div class="fixed bottom-8 right-8" style={{ zIndex: "99" }}>
      <button
        class="bg-[#051d49e4] hover:bg-[#051d49] text-white font-bold py-2 px-2 rounded-full shadow-lg"
        onClick={() => {
          setCopilotShow(!copilotShow);
        }}
      >
        <img
          src={copilotSVG}
          alt="copilot icon"
          style={{ width: "55px", height: "55px" }}
        />
      </button>
      <SlidingPane
        style={{ zIndex: "99" }}
        className="some-custom-class"
        overlayClassName="myOverlay"
        isOpen={copilotShow}
        title="Copilot"
        width="100%"
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setCopilotShow(false);
        }}
        from="bottom"
      >
        <iframe
          src="https://copilot.microsoft.com/"
          scrolling="no"
          allowfullscreen
          frameborder="0"
          style={{ height: "100%", width: "100%" }}
          className="h-auto w-auto object-contain m-auto"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        ></iframe>
      </SlidingPane>
    </div>
  );
}

export default Fab;
