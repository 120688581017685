import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import QuickLinks from "./QuickLinks";
import CompanyDoa from "./CompanyDoa";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/quick-links",
    element: <QuickLinks />,
  },
  {
    path: "/company-doa",
    element: <CompanyDoa />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <RouterProvider router={router} />
);
