import React, { useState } from "react";
import doa1 from "./assets/doa01.jpg";
import doa2 from "./assets/doa02.jpg";
import doa3 from "./assets/doa03.pdf";

const CompanyDoa = () => {
    const doaDocuments = [
        {
            type: "image",
            src: doa1,
            alt: "Company DOA Document 1",
        },
        {
            type: "image",
            src: doa2,
            alt: "Company DOA Document 2",
        },
        {
            type: "pdf",
            src: doa3,
            alt: "Company DOA Document 3",
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isMaximized, setIsMaximized] = useState(false);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? doaDocuments.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === doaDocuments.length - 1 ? 0 : prevIndex + 1
        );
    };

    const toggleMaximize = () => {
        setIsMaximized(!isMaximized);
    };

    return (
        <div className="container m-auto mt-8">
            <h1 className="font-bold text-2xl text-left pl-3 text-gray-900">
                Company DOA Documents 📄
            </h1>
            <div className={`mt-5 relative ${isMaximized ? 'fixed inset-0 z-50 bg-black' : ''}`}>
                <div className={`flex justify-center items-center ${isMaximized ? 'h-screen' : 'h-[70vh]'}`}>
                    {doaDocuments[currentIndex].type === "image" ? (
                        <img
                            src={doaDocuments[currentIndex].src}
                            alt={doaDocuments[currentIndex].alt}
                            className={`h-auto ${isMaximized ? 'max-h-screen' : 'max-h-[70vh]'} w-auto object-contain cursor-pointer`}
                            onClick={toggleMaximize}
                            onDoubleClick={toggleMaximize}
                        />
                    ) : (
                        <iframe
                            src={doaDocuments[currentIndex].src}
                            title={doaDocuments[currentIndex].alt}
                            className={`${isMaximized ? 'w-full h-screen' : 'w-full h-[70vh]'}`}
                            frameBorder="0"
                        />
                    )}
                </div>
                {!isMaximized && (
                    <>
                        <button
                            onClick={handlePrev}
                            className="absolute top-1/2 left-1 -translate-y-1/2 bg-[#051d49] text-white px-3 py-2 rounded-full"
                        >
                            &#8592;
                        </button>
                        <button
                            onClick={handleNext}
                            className="absolute top-1/2 right-1 -translate-y-1/2 bg-[#051d49] text-white px-3 py-2 rounded-full"
                        >
                            &#8594;
                        </button>
                    </>
                )}
                {isMaximized && (
                    <button
                        onClick={toggleMaximize}
                        className="absolute top-4 right-1 bg-[#051d49] text-white px-3 py-2 rounded-full"
                    >
                        &#10005;
                    </button>
                )}
                {!isMaximized && (
                    <button
                        onClick={toggleMaximize}
                        className="absolute bottom-4 right-1 bg-[#051d49] text-white px-3 py-2 rounded-full"
                    >
                        &#x26F6;
                    </button>
                )}
            </div>
            {!isMaximized && (
                <div className="flex justify-center mt-4">
                    {doaDocuments.map((doc, index) => (
                        doc.src.toLowerCase().endsWith('.pdf') ? (
                            <div
                                key={index}
                                className={`h-16 w-16 flex items-center justify-center mx-2 cursor-pointer ${index === currentIndex ? "border-2 border-blue-500" : ""
                                    }`}
                                onClick={() => setCurrentIndex(index)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12">
                                    <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625z" />
                                    <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
                                </svg>
                                <span className="text-xs text-center mt-1">PDF</span>
                            </div>
                        ) : (
                            <img
                                key={index}
                                src={doc.src}
                                alt={doc.alt}
                                className={`h-16 w-auto object-cover mx-2 cursor-pointer ${index === currentIndex ? "border-2 border-blue-500" : ""
                                    }`}
                                onClick={() => setCurrentIndex(index)}
                            />
                        )
                    ))}
                </div>
            )}
        </div>
    );
};

export default CompanyDoa;
