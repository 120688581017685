import "./Loading.css";
import loadingLogo from "../assets/CRUISE_SAUDI_LOGO_REVERSE_1_B.png";

const Loading = () => {
  return (
    <div
      className="bg-[#051d49]"
      style={{
        width: "100%",
        height: "100vh",
        position: "absolute",
        zIndex: "100",
      }}
    >
      <div id="loader-container">
        <img src={loadingLogo} />
      </div>
    </div>
  );
};
export default Loading;
