import Icon1 from "../assets/exit.png";
import Icon2 from "../assets/refund.png";
import Icon3 from "../assets/curriculum-vitae.png";
import Icon4 from "../assets/interview.png";
import Icon5 from "../assets/job-recruitment.png";
import Icon6 from "../assets/procurement.png";
import Icon7 from "../assets/asset-management.png";
import Icon8 from "../assets/erp_2.png";
import Icon9 from "../assets/support.png";
import Icon10 from "../assets/customer.png";

// #0B1B48

const quickLinksData = [
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon1,
    title: "Employee Leave Request",
    link: "https://hcm23.sapsf.com/xi/ui/ect/pages/absence/timeOff.xhtml",
  },
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon2,
    title: "Reimbursement Request",
    link: "https://apps.powerapps.com/play/e/default-8b7dc3e6-7ed1-4089-ab96-f61a1bbc019d/a/48b7e1a0-f5ca-4046-85d0-24f3a356c245?tenantId=8b7dc3e6-7ed1-4089-ab96-f61a1bbc019d&hint=156cd723-5d19-4c26-8cba-6bacac424615&sourcetime=1719910587229&?hidenavbar=true",
  },
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon3,
    title: "Employee Letter Request",
    link: "https://forms.office.com/Pages/ResponsePage.aspx?id=5sN9i9F-iUCrlvYaG7wBnQhFtQuhbeVBtfaqGDxY9QNUM0I3TDJYMVpIQlJNSlM3QUNZS1Y3NzVHSC4u&embed=true",
  },
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon4,
    title: "View My Pending HR Requests",
    link: "https://hcm23.sapsf.com/xi/ui/ect/pages/pendingWorkflows/pendingWorkflowV12.xhtml",
  },
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon5,
    title: "Purchase Requisition",
    link: "https://my312295.s4hana.ondemand.com/ui#PurchaseRequisition-createSSP&/home",
  },
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon6,
    title: "Receive Procured Services",
    link: "https://my312295.s4hana.ondemand.com/ui#ServiceEntrySheet-manage",
  },
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon7,
    title: "Receive Procured Assets",
    link: "https://my312295.s4hana.ondemand.com/ui#PurchaseOrder-createGR",
  },
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon8,
    title: "View My Pending ERP Approvals",
    link: "https://my312295.s4hana.ondemand.com/ui#WorkflowTask-displayInbox?allItems=true",
  },
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon9,
    title: "Report a Technical Issue",
    link: "https://itsm.cruisesaudi.com/support/tickets/new",
  },
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon10,
    title: "Request a Service",
    link: "https://itsm.cruisesaudi.com/support/catalog/items",
  },
];

export default quickLinksData;
