
const CruiseSchedule = ({ color }) => {
  return (
    <div className="m-auto container mt-8">
      <h1 className={`font-bold text-2xl text-left pl-3 ${color} mb-4`}>
        Cruise Schedule 🛳️
      </h1>
      <iframe
        src="https://apps.powerapps.com/play/e/default-8b7dc3e6-7ed1-4089-ab96-f61a1bbc019d/a/1fa91218-a0de-4bb6-8bc6-9a331b0434d8?tenantId=8b7dc3e6-7ed1-4089-ab96-f61a1bbc019d&sourcetime=1720950849088"
        scrolling="no"
        allowFullScreen
        frameBorder="0"
        style={{ height: "743px", width: "100%" }}
        className="h-auto w-auto object-contain m-auto"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      ></iframe>
    </div>
  );
};

export default CruiseSchedule;
