
const TopicOfTheMonth = ({ topicOfTheMonth, color }) => {
  return (
    <div className="m-auto container mt-8">
      <h1 className={`font-bold text-2xl text-left pl-3 ${color}`}>
        Topic of the month 🎓
      </h1>
      <div className="h-4"></div>
      <div
        className="overflow-hidden rounded-lg bg-white shadow cursor-pointer"
        onClick={() => window.open(topicOfTheMonth.link, "_blank")}
      >
        <div className="px-1 py-1 sm:p-5">
          <div className="flex flex-col sm:flex-row items-start">
            <div className="flex-shrink-0">
              <img
                className="rounded-lg"
                src={topicOfTheMonth.image}
                alt="Topic of the Month"
              />
            </div>
            <div className="flex ml-0 sm:ml-6 flex-grow text-left mt-4 sm:mt-0">
              <div>
                <p className="text-2xl font-medium text-gray-900">
                  {"Topic of The Month! " + topicOfTheMonth.title}
                </p>
                <p className="text-sm text-gray-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-6"
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      marginRight: "6px",
                    }}
                  >
                    <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
                    <path
                      fillRule="evenodd"
                      d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="font-bold">Recommended by </span>
                  {"Cruise Saudi on " + topicOfTheMonth.recommendationDate}
                </p>
                <p className="text-sm text-gray-700 font-bold">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6"
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      marginRight: "6px",
                    }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  {topicOfTheMonth.dueDate}
                </p>
              </div>
              <div className="ml-0 sm:ml-6 flex-shrink-0 text-left mt-4 sm:mt-0">
                <p className="text-sm text-gray-700 font-bold">
                  Learning path details
                </p>
                <p className="text-sm text-gray-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6"
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      marginRight: "6px",
                    }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  <span className="font-bold">
                    {topicOfTheMonth.lengthTime}
                  </span>{" "}
                  of content
                </p>
                <p className="text-sm text-gray-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6"
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      marginRight: "6px",
                    }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                    />
                  </svg>
                  <span className="font-bold">
                    {topicOfTheMonth.lengthVideos}
                  </span>{" "}
                  of learning content
                </p>
                <br />
                <a
                  href={topicOfTheMonth.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-white bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded"
                >
                  View Topic
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicOfTheMonth;
