import React, { useState } from "react";
import {
  Drawer,
  Button,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import CustomSmallCard from "./CustomSmallCard";

import {
  Img01,
  Img02,
  Img03,
  Img04,
  Img05,
  Img06,
  Img07,
  Img08,
  Img09,
  Img10,
  Img11,
  Img12,
  Img13,
} from "./CustomImage";

export function CustomDrawer({ theme }) {
  const [open, setOpen] = useState(false);

  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  let color = theme === "Light" ? "black" : "white";

  let apps = [
    {
      title: "Outlook",
      link: "https://outlook.office.com/owa/?realm=CruiseSaudi.com",
      icon: Img01,
    },
    {
      title: "OneDrive",
      link: "https://cruisesaudi-my.sharepoint.com/",
      icon: Img02,
    },
    {
      title: "Word",
      link: "https://www.microsoft365.com/launch/word?auth=2",
      icon: Img03,
    },
    {
      title: "Excel",
      link: "https://www.microsoft365.com/launch/excel?auth=2",
      icon: Img04,
    },
    {
      title: "PowerPoint",
      link: "https://www.microsoft365.com/launch/powerpoint?auth=2",
      icon: Img05,
    },
    // {
    //   title: "OneNote",
    //   link: "https://www.microsoft365.com/launch/onenote?auth=2",
    //   icon: Img06,
    // },
    {
      title: "SharePoint",
      link: "https://cruisesaudi.sharepoint.com/_layouts/15/sharepoint.aspx",
      icon: Img07,
    },
    {
      title: "Teams",
      link: "https://aka.ms/mstfw",
      icon: Img08,
    },
    {
      title: "Planner",
      link: "https://tasks.office.com/CruiseSaudi.com/en-US/Home/Planner/",
      icon: Img09,
    },
    // {
    //   title: "Admin",
    //   link: "https://admin.microsoft.com/#/homepage",
    //   icon: Img10,
    // },
    // {
    //   title: "Power Apps",
    //   link: "https://make.powerapps.com/environments/Default-8b7dc3e6-7ed1-4089-ab96-f61a1bbc019d/home",
    //   icon: Img11,
    // },
    // {
    //   title: "Power Auto...",
    //   link: "https://make.powerautomate.com/environments/Default-8b7dc3e6-7ed1-4089-ab96-f61a1bbc019d/home",
    //   icon: Img12,
    // },
    // {
    //   title: "Power BI",
    //   link: "https://app.powerbi.com/home?experience=power-bi",
    //   icon: Img13,
    // },
  ];

  return (
    <React.Fragment>
      <a
        className="inline-block rounded-full p-3 text-indigo-950 hover:bg-indigo-950 hover:text-white focus:outline-none focus:ring active:bg-indigo-950"
        href="#"
        onClick={openDrawer}
      >
        <span className="sr-only"> Apps </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke={color}
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z"
          />
        </svg>
      </a>
      <Drawer open={open} onClose={closeDrawer} className="p-4">
        <div className="mb-6 flex items-center justify-between">
          <a
            className="inline-block rounded-full p-3 text-indigo-950 hover:bg-indigo-950 hover:text-indigo-800 focus:outline-none focus:ring active:bg-indigo-950"
            href="#"
            onClick={closeDrawer}
          >
            <span className="sr-only"> Apps </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z"
              />
            </svg>
          </a>
          <a href="https://www.microsoft365.com/?auth=2&home=1" target="_blank">
            <Typography color="blue-gray" className="hover:underline font-bold">
              Microsoft 365 →
            </Typography>
          </a>
        </div>
        <Typography variant="h5" className="mb-8 font-bold text-left">
          Apps
        </Typography>
        <div className="flex flex-wrap gap-1">
          {apps.map((app, key) => (
            <CustomSmallCard
              title={app.title}
              link={app.link}
              icon={app.icon}
              key={key}
            />
          ))}
        </div>
      </Drawer>
    </React.Fragment>
  );
}
