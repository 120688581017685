import Icon1 from "../assets/sap.png";
import Icon2 from "../assets/settings.png";
import Icon3 from "../assets/request.png";
import Icon4 from "../assets/erp.png";
import Icon5 from "../assets/reading.png";
import Icon6 from "../assets/auditing.png";
import Icon7 from "../assets/pdf.png";
import Icon8 from "../assets/elearning.png";
import Icon9 from "../assets/chat.png";
import Icon10 from "../assets/rocket-launch.png";
import Icon11 from "../assets/balance.png";
import Icon12 from "../assets/online-learning.png";
import Icon13 from "../assets/hacker.png";

// #0B1B48

const companyResourcesData = [
  {
    isIFrame: true,
    isUnique: false,
    disclaimer: () => (
      <div className="space-y-4 text-gray-800">
        <p className="font-semibold">Dear Team,</p>
        <p className="text-md">Thank you for your interest in contributing to the innovation process. Please note that submitted ideas should focus on innovation / Digitization that benefits departments within Cruise Saudi and relates directly to operational or work-related improvements.</p>
        <p className="text-md">Ideas regarding facility requirements, such as maintenance or workspace-related issues, should be directed to the appropriate Workplace Services or Facilities Management teams.</p>
        <p className="text-md italic">We appreciate your understanding and look forward to receiving your innovative Ideas.</p>
      </div>
    ),
    icon: Icon9,
    title: "Employee Ideas & Feedback",
    link: "https://forms.office.com/Pages/ResponsePage.aspx?id=5sN9i9F-iUCrlvYaG7wBnfz7FvTZTmRNmFU-F2bqmPdURTNEVjZCTUNLR0tLNEI5WEJVSVM1VVZaTC4u&embed=true",
  },
  {
    isIFrame: true,
    isUnique: false,
    icon: Icon10,
    title: "Quick Links",
    link: "/quick-links",
  },
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon1,
    title: "Human Resource System",
    link: "https://hcm23.sapsf.com/saml2/Login?company=redseacrui&RelayState=/login?company=redseacrui&_s.crb=h6byVQnuU4bIQSfIgRIn5bG6e1uIgM7qU0NBUzfQW7M%253d",
  },
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon4,
    title: "ERP System",
    link: "https://my312295.s4hana.ondemand.com/",
  },
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon2,
    title: "IT Service Management",
    link: "https://itsm.cruisesaudi.com/support/login",
  },
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon11,
    title: "Legal Services",
    link: "https://itsm.cruisesaudi.com/support/catalog/items?category_id=22000062889",
  },
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon5,
    title: "Knowledge Base",
    link: "https://cruisesaudi.sharepoint.com/sites/KnowledgeBase",
  },
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon3,
    title: "GEMS Platform",
    link: "https://app.smartsheet.com/b/publish?EQBCT=518914d94e66472e900ca5987aed3375",
  },
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon6,
    title: "Internal Audit Platform",
    link: "https://accounts.highbond.com/login",
  },
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon7,
    title: "Advanced PDF tools",
    link: "http://10.0.5.4:808",
  },
  {
    isIFrame: false,
    isUnique: false,
    icon: Icon12,
    title: "eLearning",
    link: "https://www.linkedin.com/learning/",
  },
  {
    isIFrame: false,
    isUnique: true,
    icon: Icon13,
    title: "Cybersecurity Incidents",
    link: "https://itsm.cruisesaudi.com/support/catalog/items?category_id=22000063753",
  },
];

export default companyResourcesData;
