
function CustomSmallCard({ title, link, icon }) {
  return (
    <button
      className="flex gap-1 items-center hover:bg-blue-gray-200 p-1"
      style={{ width: "130px", height: "45px" }}
    >
      <img src={icon} style={{ width: "28px", height: "28px" }} />
      <a href={link} target="_blank" style={{ fontSize: "14px" }}>
        {title}
      </a>
    </button>
  );
}

export default CustomSmallCard;
