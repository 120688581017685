import logo from "../assets/CRUISE_SAUDI_LOGO_2.png"; // replace this with the path to your logo
import { CustomDrawer } from "./CustomDrawer";
// import icon from "../assets/icon.svg"; // replace this with the path to your icon

const Header = ({ theme }) => {
  let color = theme === "Light" ? "text-gray-900" : "text-white";
  let color2 = theme === "Light" ? "text-gray-800" : "text-white";
  let logoColor = theme === "Light" ? "" : "White-App-logo";

  return (
    <header>
      <div className=" max-w-screen-xl px-4 py-4 sm:px-6 sm:py-8 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="flex items-center">
            <CustomDrawer theme={theme} />
            <img
              src={logo}
              alt="Logo"
              className={`h-16 w-auto mx-auto pr-12 ${logoColor}`}
            />
          </div>
          <div className="text-center sm:text-left">
            <h1 className={`font-bold text-2xl ${color}`}>
              Welcome to Cruise Saudi Hub
            </h1>

            <p className={`mt-1.5 text-sm ${color2}`}>
              All relevant links in one place!
            </p>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
