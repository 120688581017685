import CustomCard from "./CustomCard";

const CompanyResources = ({ companyResources, color }) => {
  return (
    <div className="container m-auto">
      <h1 className={`font-bold text-2xl text-left pl-3 ${color}`}>
        Company Resources 🗄️
      </h1>
      <div className="flex flex-wrap mt-5 lg:justify-start sm:justify-center justify-center">
        {companyResources.map((item, key) => (
          <div className="mr-1 ml-1 mb-3" key={key}>
            <CustomCard
              icon={item.icon}
              title={item.title}
              link={item.link}
              isUnique={item.isUnique}
              isIFrame={item.isIFrame}
              disclaimer={item.disclaimer ?? ""}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyResources;
